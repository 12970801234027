const filterMap = {
    user_level: { text: { 1: '普通', 2: '银卡', 3: '金卡', 4: '铂金', 5: '黑卡' }, class: { 1: 'puka', 2: 'yinka', 3: 'jinka', 4: 'bojin', 5: 'heika' } },
}

export function filterText (type, key, map = null) {
    if (map != null) {
        return map[key]
    }
    if (filterMap[type] == undefined || filterMap[type].text[key] == undefined) {
        return ""
    }
    return filterMap[type].text[key]
}

export function filterClass (type, key) {
    if (filterMap[type] == undefined || filterMap[type].class[key] == undefined) {
        return ""
    }
    return filterMap[type].class[key]
}
