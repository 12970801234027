export default {
    namespaced: true,
    state: {
        user: undefined,
    },
    getters: {
        user: state => {
            if (!state.user) {
                try {
                    const user = localStorage.getItem('user')
                    state.user = JSON.parse(user)
                } catch (e) {
                    console.error(e)
                }
            }
            return state.user
        },
    },
    mutations: {
        setUser (state, user) {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
        },
    }
}