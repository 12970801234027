import Vue from 'vue'
import App from './App.vue'
import Vant,{Lazyload,Dialog} from 'vant'

// 全局注册
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import { filterText, filterClass } from './utils/filter'
import "./assets/css/style.less";
// 状态处理方法
Vue.prototype.$filterText = filterText
Vue.prototype.$filterClass = filterClass
Vue.prototype.$getImage = function(url){
  // console.log(url);
  if(!url){
    return '';
  }
  
  if(url.substr(0,4).toLowerCase() == "http"){
    return url;
  }
  return process.env.VUE_APP_API_BASE_URL + url;
}

import 'vant/lib/index.css'
Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(Dialog);
Vue.use(Lazyload);
Vue.use(Vuex)
Vue.use(VueRouter)

import router from './router'
import store from './store'
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
