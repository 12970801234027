<template>
  <div id="app">
    <van-nav-bar
        placeholder 
        fixed
        :title="$route.meta.title"
        :left-text="$route.meta.back ? '返回' : ''"
        :right-text="$route.meta.home ? '首页' : ''"
        :left-arrow="$route.meta.back"
        @click-left="() => $router.go(-1)"
        @click-right="() => $router.push({path:'/'})"
    />
    <router-view v-if="!$route.meta.keepAlive"></router-view> 
    <keep-alive><router-view v-if="$route.meta.keepAlive"></router-view></keep-alive>
    <tabbar v-if="$route.meta.tabbarShow"></tabbar>
    <!-- <customerService></customerService> -->
  </div>
</template>

<script>
import tabbar from '@/components/common/tabbar'
// import customerService from '@/components/common/customerService'
export default {
  name: 'App',
  components: {
    tabbar,
    // customerService
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
.van-pull-refresh__track {
  min-height: calc(100vh - 100px);
}
</style>
