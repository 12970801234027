const routes = [
    {
        path:'/',
        name:'home',
        meta:{
            title:'首页',
            tabbarShow:true,
            back:false,
        },
        component:() => import('@/pages/home')
      },
    {
        path:'/login',
        name:'login',
        meta:{
            title:'登录',
            tabbarShow:true,
            back:true,
        },
        component:() => import('@/pages/user/login')
    },
    {
        path:'/register',
        name:'register',
        meta:{
            title:'注册',
            tabbarShow:true,
            back:true,
        },
        component:() => import('@/pages/user/register')
    },
    {
        path:'/resetPassword',
        name:'resetPassword',
        meta:{
            title:'重置密码',
            tabbarShow:true,
            back:true,
        },
        component:() => import('@/pages/user/resetPassword')
    },
    {

      path:'/home',
      name:'home',
      meta:{
        title:'首页',
        tabbarShow:true,
        back:false,
    },
      component:() => import('@/pages/home')
    },
    {

        path:'/vip',
        name:'vip',
        meta:{
            title:'会员专区',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/home/vip')
    },
    {

        path:'/recommend',
        name:'recommend',
        meta:{
            title:'推荐有礼',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/home/recommend')
    },
    {

        path:'/introduce',
        name:'introduce',
        meta:{
            title:'品牌介绍',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/home/introduce')
    },
    {

        path:'/article',
        name:'article',
        meta:{
            title:'变美日记',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/article')
    },
    {

        path:'/article/detail',
        name:'article_detail',
        meta:{
            title:'变美日记',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/article/detail')
    },
    {
        path:'/integral',
        name:'integral',
        meta:{
            title:'积分商城',
            tabbarShow:true,
            back:false,
        },
        component:() => import('@/pages/integral')
    },
    {
        path:'/integral/exchange',
        name:'integral_exchange',
        meta:{
            title:'积分兑换',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/integral/exchange')
    },
    {
        path:'/integral/exchangeLog',
        name:'integral_exchange_log',
        meta:{
            title:'兑换记录',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/integral/exchangeLog')
    },
    {
        path:'/integral/exchangePrice',
        name:'integral_exchange_price',
        meta:{
            title:'积分兑换现金',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/integral/exchangePrice')
    },
    {
        path:'/integral/exchangePriceLog',
        name:'integral_exchange_price_log',
        meta:{
            title:'兑换现金记录',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/integral/exchangePriceLog')
    },
    {
        path:'/integral/flow',
        name:'integral_flow',
        meta:{
            title:'积分流水',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/integral/flow')
    },
    {
        path:'/user',
        name:'user',
        meta:{
            title:'我的',
            tabbarShow:true,
            back:false,
        },
        component:() => import('@/pages/user')
    },
    {
        path:'/goods/salesGoods',
        name:'sales_goods',
        meta:{
            title:'当季活动',
            tabbarShow:true,
            back:false,
        },
        component:() => import('@/pages/goods/salesGoods')
    },
    {
        path:'/goods/vipGoods',
        name:'vip_goods',
        meta:{
            title:'会员购',
            tabbarShow:true,
            back:false,
        },
        component:() => import('@/pages/goods/vipGoods')
    },
    {
        path:'/goods/detail',
        name:'goods_detail',
        meta:{
            title:'商品详情',
            tabbarShow:false,
            back:true,
            home:true,
        },
        component:() => import('@/pages/goods/detail')
    },
    {
        path:'/cart',
        name:'cart_list',
        meta:{
            title:'购物车',
            tabbarShow:false,
            back:true,
            home:true,
        },
        component:() => import('@/pages/cart/index')
    },
    {
        path:'/order/confirmByCart',
        name:'order_confirm_by_cart',
        meta:{
            title:'购物车确认订单',
            tabbarShow:false,
            back:true,
            home:true,
        },
        component:() => import('@/pages/order/confirmByCart')
    },
    {
        path:'/order/confirm',
        name:'order_confirm',
        meta:{
            title:'确认订单',
            tabbarShow:false,
            back:true,
            home:true,
        },
        component:() => import('@/pages/order/confirm')
    },
    {
        path:'/order/list',
        name:'order_list',
        meta:{
            title:'订单列表',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/order/list')
    },
    {
        path:'/ms/goods',
        name:'ms_goods',
        meta:{
            title:'秒杀专区',
            tabbarShow:false,
            back:true,
            home:true,
        },
        component:() => import('@/pages/ms/goods')
    },
    {
        path:'/ms/orderConfirm',
        name:'ms_order_confirm',
        meta:{
            title:'秒杀订单确认',
            tabbarShow:false,
            back:true,
            home:true,
        },
        component:() => import('@/pages/ms/orderConfirm')
    },
    {
        path:'/ms/orderConfirmNoLogin',
        name:'ms_order_confirm_no_login',
        meta:{
            title:'秒杀订单确认(未注册)',
            tabbarShow:false,
            back:true,
            home:true,
        },
        component:() => import('@/pages/ms/orderConfirmNoLogin')
    },
    {
        path:'/ms/activityList',
        name:'ms_activity_list',
        meta:{
            title:'秒杀活动专区',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/ms/activityList')
    },
    {
        path:'/ms/orderList',
        name:'ms_order_list',
        meta:{
            title:'秒杀订单',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/ms/orderList')
    },
    {
        path:'/ms/orderListNoLogin',
        name:'ms_order_list_no_login',
        meta:{
            title:'秒杀订单(未登录)',
            tabbarShow:true,
            back:true,
            keepAlive:true,
            home:true,
        },
        component:() => import('@/pages/ms/orderListNoLogin')
    },
    {
        path:'/ms/orderDetail',
        name:'ms_order_detail',
        meta:{
            title:'秒杀订单详情',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/ms/orderDetail')
    },
    {
        path:'/pushOrder/list',
        name:'push_order_list',
        meta:{
            title:'推送订单',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/push_order/list')
    },
    {
        path:'/pushOrder/confirm',
        name:'push_order_confirm',
        meta:{
            title:'推送订单确认',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/push_order/confirm')
    },
    {
        path:'/pushOrder/detail',
        name:'push_order_detail',
        meta:{
            title:'推送订单确认',
            tabbarShow:true,
            back:true,
            home:true,
        },
        component:() => import('@/pages/push_order/detail')
    },
];

import VueRouter from "vue-router"

export default new VueRouter({
    routes:routes
})