<template>
    <van-tabbar route active-color="#30A3F8" :placeholder="true">
      <van-tabbar-item 
        v-if="(item.title != '会员购') || isVipDate"
        v-for="(item,index) in tabbars" 
        :key="index" 
        :to="(item.name)"
        :icon="item.icon"
      >
      {{item.title}}
        
      </van-tabbar-item>
    </van-tabbar>
  </template>
  <script>
  export default {
    name: "tabbar",
    props: {

    },
    data() {
      return {
        tabbars: [
          {
            name: "/home",
            title: "首页",
            icon: 'wap-home-o',
          },
          {
            name: "/goods/salesGoods",
            title: "当季活动",
            icon: 'shop-o',
          },
          {
            name: "/goods/vipGoods",
            title: "会员购",
            icon: 'bag-o',
          },
          {
            name: "/integral",
            title: "积分商城",
            icon: 'point-gift-o',
          },
          {
            name: "/user",
            title: "我的",
            icon: 'friends-o',
          }
        ]
      };
    },
    created () {

    },
    methods:{
        
    },
    computed:{
      isVipDate(){
          let myDate = new Date()  
		      let wk = myDate.getDay();
          if(wk == 4){
            return true;
          }

          return false
        }
    }
  };
  </script>
  
  <style scoped>
  .van-tabbar {
    border-top: 1px solid #EEE;
    height: 55px;
    
  }

  .van-tabbar-item__icon img {
      width: 22px;
      height: 22px;
    }
  
  .active_tab img {
    width: 22px;
    height: 22px;
  }
  </style>